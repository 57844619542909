import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Chip from "~/components/atoms/Chip";
import Typo from "~/components/atoms/Typo";
import { IProject } from "~/entities/project";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface CardDetailsProps {
  project: IProject;
  showUnitsState?: boolean;
}

const CardDetails: React.FC<CardDetailsProps> = ({
  project,
  showUnitsState = true,
}) => {
  const { t } = useTranslation();
  const [totalUnits, setTotalunits] = useState<string>("0");
  const [availableUnits, setAvailableUnits] = useState<string>("0");
  const [soldUnits, setSoldUnits] = useState<string>("0");
  const [expectedTOP, setExpectedTOP] = useState<string>("");

  useEffect(() => {
    if (isEmpty(project)) return;

    if (isEmpty(project?.unitTypes) || isNaN(project?.totalUnits)) {
      setTotalunits("TBC");
    } else {
      setTotalunits(project?.totalUnits.toString());
    }

    if (isEmpty(project?.unitTypes) || isNaN(project?.availableUnits)) {
      setAvailableUnits("TBC");
    } else {
      setAvailableUnits(project?.availableUnits.toString());
    }

    if (
      isEmpty(project?.unitTypes) ||
      isNaN(project?.totalUnits) ||
      isNaN(project?.availableUnits)
    ) {
      setSoldUnits("TBC");
    } else {
      setSoldUnits((project?.totalUnits - project?.availableUnits)?.toString());
    }

    if (dayjs(project?.expectedTop).isValid()) {
      setExpectedTOP(dayjs(project?.expectedTop).format("MMM YYYY"));
    } else {
      setExpectedTOP(project?.expectedTop?.toString());
    }
  }, [project]);

  if (!project) return null;

  return (
    <>
      <div className="flex min-h-5 flex-nowrap items-end justify-start gap-3 lg:gap-1">
        <Typo
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.CARD}
          className="whitespace-nowrap"
        >
          {expectedTOP ? expectedTOP : t("no_TOP_value")}
        </Typo>
        {project.tenure && (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.CARD}
          >
            |
          </Typo>
        )}
        {project.tenure && (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD}
            className="whitespace-nowrap"
          >
            {TENURE_ITEM[project.tenure]?.name || ""}
          </Typo>
        )}
        {project.tenure && project.address && (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.CARD}
          >
            |
          </Typo>
        )}
        <Typo
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.SUB_TITLE_16}
          color={ETypoColor.CARD}
          className="truncate"
        >
          {project.address}
        </Typo>
      </div>
      {showUnitsState && (
        <div className="flex flex-wrap gap-2">
          <Chip
            className="flex flex-nowrap items-center justify-start gap-1 bg-cardChipBackground"
            label=""
            theme="light"
            variant="primary"
          >
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {t("total")}:{" "}
            </Typo>
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {totalUnits}
            </Typo>
          </Chip>
          <Chip
            className="flex flex-nowrap items-center justify-start gap-1 bg-cardChipBackground"
            label=""
            theme="light"
            variant="primary"
          >
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {t("available")}:{" "}
            </Typo>
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {availableUnits}
            </Typo>
          </Chip>
          {/* <Chip
            className="flex flex-nowrap items-center justify-start gap-1 bg-cardChipBackground"
            label=""
            theme="light"
            variant="primary"
          >
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {t("sold")}:{" "}
            </Typo>
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_CHIP}
            >
              {soldUnits}
            </Typo>
          </Chip> */}
        </div>
      )}
    </>
  );
};

export default CardDetails;
