import { Link, useNavigate, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonCompare from "../ButtonCompare";
import UnitTypeTabs from "../UnitTypeTabs";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import CardDetails from "~/components/molecules/CardDetails";
import { IProject } from "~/entities/project";
import { TUnitType } from "~/entities/unitType";
import useAppState from "~/stores";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";
import processPrices from "~/utilities/helpers/processPrices";
import { convertUnitTypeOptions } from "~/utilities/helpers/unitType";

interface ICardLayout {
  [key: string]: {
    card: string;
    conpareButton: string;
    body: string;
    figure: string;
    name: string;
  };
}

// Define variants and sizes directly in the component
const layouts: ICardLayout = {
  vertical: {
    card: "flex-col",
    conpareButton: "top-5 right-5 lg:right-3 lg:top-3",
    body: "-mt-6 p-5 lg:p-3",
    figure: "rounded-t-3xl lg:rounded-t-lg",
    name: "",
  },
  horizontal: {
    card: "flex-row max-h-[340px]",
    conpareButton: "top-5 left-5 lg:top-3 lg:left-3 flex-row-reverse",
    body: "-ml-6 flex flex-col justify-center fit-content w-2/5",
    figure: "max-h-full h-[340px] rounded-l-3xl lg:rounded-l-lg",
    name: "text-header-24 lg:text-header-20",
  },
};

export interface CardProjectProps {
  className?: string;
  thumbnailClass?: string;
  bodyWrapperClass?: string;
  project: IProject;
  layout?: "vertical" | "horizontal";
  hideDetails?: boolean;
  showTenure?: boolean;
  showAddress?: boolean;
  showUnitsState?: boolean;
  showTabsUnitTypes?: boolean;
  full?: boolean;
  typeButton?: "short" | "long";
  isShownCompareButton?: boolean;
  handleOnSelectProject?: () => void;
}

const CardProject: React.FC<CardProjectProps> = ({
  className,
  thumbnailClass = "",
  project,
  layout = "vertical",
  hideDetails = false,
  showTenure = true,
  showAddress = false,
  showUnitsState = true,
  showTabsUnitTypes = true,
  full,
  typeButton = "short",
  isShownCompareButton = true,
  handleOnSelectProject,
  bodyWrapperClass = "",
}) => {
  const thumbnail = findFirstJpegSource(project?.photo ? [project?.photo] : []);
  const [availableUnitTypes, setAvailableUnitTypes] = useState<TUnitType[]>([]);
  const { filterOptions } = useRouteLoaderData("root") as IRouteLoaderData;
  const convertedUnitOptions = convertUnitTypeOptions(filterOptions?.unitTypes);
  const defaultUnitOptions = convertedUnitOptions?.map((init) => ({
    id: init?.id,
    title: init?.name,
    totalUnits: 0,
    availableUnits: 0,
    isStudio: true,
  })) as TUnitType[];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const cardClass = cn(
    "card bg-cardProjectBackground rounded-3xl lg:rounded-lg justify-between shadow-none hover:shadow-card-project-hover lg:hover:shadow-none transition-shadow duration-200 h-fit",
    layouts[layout].card,
    full && "w-full h-full",
    className
  );

  const bodyClass = cn(
    "card-body relative flex-grow-0 flex-shrink-0 bg-cardProjectBackground gap-2 lg:gap-3 rounded-3xl lg:rounded-lg",
    layouts[layout].body
  );

  const nameClass = cn(
    "truncate whitespace-nowrap font-bold uppercase",
    layouts[layout].name
  );

  const compareButtonClass = cn("absolute z-10", layouts[layout].conpareButton);

  const figureClass = cn(
    "w-full h-full h-[169px] bg-backgroundImage grow overflow-hidden",
    layouts[layout].figure,
    thumbnailClass,
    full && "max-h-full max-w-full"
  );

  const { comparedProjects, addComparedProject } = useAppState(
    (state) => state
  );

  const isAdded = comparedProjects.find(
    (comparedProject) => comparedProject.id === project.id
  );

  useEffect(() => {
    const unitTypes = project?.unitTypes?.filter((unitType) => {
      const unitPrice = processPrices(
        unitType?.totalUnits,
        unitType?.availableUnits,
        unitType?.minPrice,
        unitType?.maxPrice
      );

      return unitPrice !== "sold_out";
    });

    if (JSON.stringify(unitTypes) !== JSON.stringify(availableUnitTypes)) {
      setAvailableUnitTypes(unitTypes || []);
    }
  }, [project, availableUnitTypes]);

  if (!project) return null;

  return (
    <Link
      to={`/projects/${project.slug}`}
      className={cardClass}
      onClick={(e) => {
        e.preventDefault();
        if (typeof handleOnSelectProject === "function") {
          handleOnSelectProject();
        }
        navigate(`/projects/${project.slug}`);
      }}
    >
      {isShownCompareButton && (
        <ButtonCompare
          addComparedProject={() => addComparedProject(project)}
          variant={typeButton}
          isAdded={isAdded ? true : false}
          classNameButton={compareButtonClass}
        />
      )}
      <figure className={figureClass}>
        <ImageResponsive
          imageData={thumbnail}
          className="h-full w-full object-cover"
        />
      </figure>
      <div className={cn(bodyClass, bodyWrapperClass)}>
        <div
          className={cn(
            "flex flex-nowrap items-center gap-2",
            showTenure ? "justify-between" : "",
            showAddress ? "justify-start" : ""
          )}
        >
          <Typo
            tag={ETypoTag.H3}
            variant={
              layout === "horizontal"
                ? ETypoVariant.SUB_TITLE_20
                : ETypoVariant.SUB_TITLE_18
            }
            color={ETypoColor.CARD_PROJECT}
            className={nameClass}
          >
            {project?.name || ""}
          </Typo>
          {showAddress && (
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.CARD_PROJECT_ADDRESS}
              className="truncate whitespace-nowrap"
            >
              {project?.address ? t("at") : ""} {project?.address}
            </Typo>
          )}
          <div className="flex gap-2">
            {showTenure && (
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.SUB_TITLE_16}
                color={ETypoColor.CARD_PROJECT_TENURE}
                className="whitespace-nowrap"
              >
                {TENURE_ITEM[project?.tenure]?.name || ""}
              </Typo>
            )}
          </div>
        </div>
        {!hideDetails && (
          <CardDetails project={project} showUnitsState={showUnitsState} />
        )}
        {showTabsUnitTypes && (
          <UnitTypeTabs
            layout={layout}
            unitTypes={
              isEmpty(availableUnitTypes)
                ? defaultUnitOptions
                : availableUnitTypes
            }
          />
        )}
      </div>
    </Link>
  );
};

export default CardProject;
